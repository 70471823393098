import {config} from './config.js';

import {
    navigateToPage
} from './router.js';

import {
    showLoginUiElements,
    showAmountEvolution, 
    showProfitEvolution 
} from './ui.js';

export const getMe = async (token) => {
    
    const response = await fetch(`${config.baseUrl}/users/me`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
    const userInfo = await response.json();
    
    localStorage.setItem('me', JSON.stringify(userInfo));

    return userInfo;
}

export const tokenEvents = async () => {
    const token = localStorage.getItem('token');
    if (token) {
        
        const userInfo = await getMe(token);
        

        showLoginUiElements(userInfo);
        showAmountEvolution(userInfo.evolution);
        showProfitEvolution(userInfo.evolution);

        // profileButton.addEventListener('click', () => {
        //     localStorage.clear();
        //     navigateToPage('home');
        // });
    } else {
        var topNoUser = document.getElementById('top-no-user');
        if (topNoUser) {
            topNoUser.classList.add('shown')
        }

    }

}

export const logoutListeners = () => {
    document.getElementById('logout-button').addEventListener('click', () => {
        localStorage.clear();
        navigateToPage('home');
    });
};