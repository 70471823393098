import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import {config} from './config.js';

import {
    formatNumber,
    showLoader,
    hideLoader
} from './utils.js';

import {
    navigateToPage
} from './router.js';

export const loadTags = async () => {
    try {
        let url = config.tagsEndpoint;
        let params = [];

        if (config.currentSearch) {
            params.push(`search=${encodeURIComponent(config.currentSearch)}`);
        }
        if (params.length > 0) {
            url += '?' + params.join('&');
        }
        const response = await fetch(url);
        config.allTags = await response.json(); // Almacenar todas las tags

        // Mostrar las tags iniciales
        await renderTags(config.allTags);
    } catch (error) {
        console.error('Error fetching tags data:', error);
    }
};

export const renderTags = async (tags) => {
    const tagsContainer = document.getElementById('tags-container');
    tagsContainer.innerHTML = ''; // Limpiar contenido anterior

    const searchInput = document.createElement('input');
    searchInput.type = 'text';
    searchInput.placeholder = 'Buscar tags';
    searchInput.addEventListener('input', () => {
        const searchTerm = searchInput.value.toLowerCase();
        const filteredTags = tags.filter(tag => tag.tag.toLowerCase().includes(searchTerm));
        renderTagsList(filteredTags);
    });
    tagsContainer.appendChild(searchInput);

    renderTagsList(tags);
};

// Función auxiliar para renderizar la lista de tags
export const renderTagsList = (tags) => {
    const tagsContainer = document.getElementById('tags-container');

    // Limpiar solo la lista de tags anterior
    const existingTagsList = tagsContainer.querySelector('.tags-list');
    if (existingTagsList) {
        tagsContainer.removeChild(existingTagsList);
    }

    const tagsList = document.createElement('div');
    tagsList.className = 'tags-list';
    if (!tags.find(tag => tag.tag === 'all')) {
        tags.unshift({tag: 'all'})
    }
    tags.slice(0, 15).forEach(tag => {
        const tagButton = document.createElement('button');
        tagButton.textContent = tag.tag;
        tagButton.className = 'tag-button';
        if (tag.tag === 'all') {
            tagButton.className = 'tag-button selected-tag' // Guardar el tag actual
        }
        tagButton.addEventListener('click', async (e) => {
            config.currentTag = tag.tag; // Guardar el tag actual
            if (tag.tag === 'all') {
                config.currentTag = '' // Guardar el tag actual
            }
            const selectedTags = tagsContainer.getElementsByClassName('selected-tag');
            if (selectedTags && selectedTags.length) {
                for (let index = 0; index < selectedTags.length; index++) {
                    const element = selectedTags[index];
                    element.className = 'tag-button';  
                }
            }
            e.target.className = 'tag-button selected-tag'
            if (config.daysSearch) {
                const endpoint = `${config.artistsEndpoint}top-growth-by-playcounts/${config.daysSearch}`;
                await updateTable(endpoint, parseInt(config.daysSearch));
            } else {
                // Por defecto, cargar el listado completo de artistas
                await updateTable(config.artistsEndpoint, 90);
            }
        });
        tagsList.appendChild(tagButton);
    });

    tagsContainer.appendChild(tagsList);
};

export const updateTable = async (endpoint = config.artistsEndpoint, days = 90) => {
    try {
        showLoader();

        let url = endpoint;
        let params = [];

        if (config.currentSearch) {
            params.push(`search=${encodeURIComponent(config.currentSearch)}`);
        }

        if (config.currentTag) {
            params.push(`tag=${encodeURIComponent(config.currentTag)}`);
        }

        if (config.byListeners) {
            params.push('bylisteners=true');
        }

        if (params.length > 0) {
            url += '?' + params.join('&');
        }

        const response = await fetch(url);
        const artists = await response.json();

        const tableBody = document.querySelector('#artists-table tbody');
        const tableHeader = document.querySelector('#artists-table thead tr');
        tableBody.innerHTML = ''; // Limpiar tabla actual

        // Limpiar contenido anterior de la cabecera del gráfico
        const chartHeaderCell = tableHeader.querySelector('.graph-col');
        if (chartHeaderCell) {
            chartHeaderCell.textContent = ''; // Limpiar contenido anterior
        }

        // Actualizar la tabla con los artistas ordenados
        artists.forEach((artist, index) => {
            const row = document.createElement('tr');

            // Crear celdas para cada columna de la tabla
            ['Rank', 'Name', 'Value', 'Last Week Growth', 'Last Month Growth', 'Last 3 Months Growth', 'Max Value', 'Chart'].forEach((label, columnIndex) => {
                const cell = document.createElement('td');
                if (label === 'Rank') {
                    cell.textContent = index + 1;
                } else if (label === 'Name') {
                    cell.textContent = artist.name;
                } else if (label === 'Value') {
                    if (config.byListeners) {
                        cell.textContent = formatNumber(artist.stocks_by_listeners);
                    } else {
                        cell.textContent = formatNumber(artist.stocks_by_playcounts_moving_average);
                    }
                    cell.classList.add('r-align');
                } else if (label === 'Last Week Growth') {
                    const growth = artist.growth_7d;
                    cell.textContent = growth !== '0' ? `${formatNumber(growth)}%` : 'N/A';
                    cell.style.color = growth >= 0 ? 'green' : 'red';
                    cell.classList.add('r-align');
                    if (!isNaN(growth)) {
                        cell.innerHTML += `<span class="arrow ${growth >= 0 ? 'up' : 'down'}"></span>`;
                    }
                } else if (label === 'Last Month Growth') {
                    const growth = artist.growth_30d;
                    cell.textContent = growth !== '0' ? `${formatNumber(growth)}%` : 'N/A';
                    cell.style.color = growth >= 0 ? 'green' : 'red';
                    cell.classList.add('r-align');
                    if (!isNaN(growth)) {
                        cell.innerHTML += `<span class="arrow ${growth >= 0 ? 'up' : 'down'}"></span>`;
                    }
                } else if (label === 'Last 3 Months Growth') {
                    const growth = artist.growth_90d;
                    cell.textContent = growth !== '0' ? `${formatNumber(growth)}%` : 'N/A';
                    cell.style.color = growth >= 0 ? 'green' : 'red';
                    cell.classList.add('r-align');
                    if (!isNaN(growth)) {
                        cell.innerHTML += `<span class="arrow ${growth >= 0 ? 'up' : 'down'}"></span>`;
                    }
                } else if (label === 'Max Value') {
                    const maxPrice = Math.max(...artist.stats.map(stat => stat.daily_playcounts_moving_average));
                    cell.textContent = formatNumber(maxPrice / 1000);
                    cell.classList.add('r-align');
                } else if (label === 'Chart') {
                    const chartContainer = document.createElement('div');
                    chartContainer.className = 'chart-container';
                    const canvas = document.createElement('canvas');
                    canvas.id = `chart-${artist.id}`;
                    chartContainer.appendChild(canvas);
                    cell.appendChild(chartContainer);

                    // Obtener datos según el período seleccionado para el gráfico
                    const statsForChart = artist.stats.slice(0, days);
                    const dates = statsForChart.map(stat => new Date(stat.date));
                    const dailyPlaycounts = statsForChart.map(stat => stat.daily_playcounts_moving_average / 1000);

                    let growthLastNDays = artist.growth_90d;
                    if (days === 7) {
                        growthLastNDays = artist.growth_7d;
                    } else if (days === 30) {
                        growthLastNDays = artist.growth_30d;
                    }

                    const ctx = canvas.getContext('2d');
                    const minY = Math.min(...dailyPlaycounts);
                    const maxY = Math.max(...dailyPlaycounts);
                    // Calcula un margen basado en un porcentaje del rango
                    const paddingPercentage = 0.1; // Por ejemplo, 10% de padding
                    const yPadding = (maxY - minY) * paddingPercentage;
                    
                    // Ajusta los valores min y max con el padding
                    const adjustedMinY = minY - yPadding;
                    const adjustedMaxY = maxY + yPadding;
                    const chart = new Chart(ctx, {
                        type: 'line',
                        data: {
                            labels: dates,
                            datasets: [{
                                data: dailyPlaycounts,
                                borderColor: growthLastNDays >= 0 ? 'green' : 'red',  // Color de la línea según el crecimiento
                                backgroundColor: 'rgba(0, 0, 0, 0)',  // Sin relleno debajo de la línea
                                borderWidth: 2,  // Grosor de la línea
                            }]
                        },
                        options: {
                            responsive: true,
                            maintainAspectRatio: false,
                            clip: false,
                            aspectRatio: 1,  // Aspect ratio personalizado
                            scales: {
                                x: {
                                    type: 'time',
                                    time: {
                                        unit: 'day',
                                        tooltipFormat: 'dd/MM/yyyy'
                                    },
                                    ticks: {
                                        display: false,  // Ocultar las etiquetas del eje x
                                    },
                                    grid: {
                                        display: false,  // Ocultar la cuadrícula del eje x
                                        drawBorder: false  // No dibujar la línea de borde del eje x
                                    },
                                    border: {
                                        display: false
                                    }
                                },
                                y: {
                                    beginAtZero: true,
                                    ticks: {
                                        display: false,  // Ocultar las etiquetas del eje y
                                    },
                                    grid: {
                                        display: false,  // Ocultar la cuadrícula del eje y
                                        drawBorder: false  // No dibujar la línea de borde del eje y
                                    },
                                    border: {
                                        display: false
                                    },
                                    min: adjustedMinY,  // Establece el valor mínimo del eje Y
                                    max: adjustedMaxY   // Establece el valor máximo del eje Y
                                }
                            },
                            plugins: {
                                legend: {
                                    display: false  // Ocultar la leyenda
                                }
                            },
                            elements: {
                                point: {
                                    radius: 0,  // Ocultar los puntos de los datos
                                    hitRadius: 10
                                },
                                line: {
                                    tension: 0.1  // Desactivar la suavidad de la línea
                                }
                            },
                            layout: {
                                padding: {
                                    right: 5
                                }
                            }
                        }
                    });

                    // Actualizar título de la cabecera del gráfico en la tabla
                    const chartHeaderCell = tableHeader.querySelector('.graph-col');
                    if (days === 7) {
                        chartHeaderCell.textContent = 'Gráfico últimos 7 días';
                    } else if (days === 30) {
                        chartHeaderCell.textContent = 'Gráfico último mes';
                    } else {
                        chartHeaderCell.textContent = 'Gráfico últimos 3 meses';
                    }
                }

                // Agregar la celda a la fila
                row.appendChild(cell);
            });

            // Agregar la fila a la tabla
            
            row.addEventListener('click', () => {
                navigateToPage('artist', artist.id);
            });
            tableBody.appendChild(row);
        });

        hideLoader(); // Ocultar loader después de la petición
    } catch (error) {
        console.error('Error fetching artists data:', error);
        hideLoader(); // Ocultar loader después de la petición
    }
};

export const addSortableClick = () => {
    const sortableHeaders = document.querySelectorAll('.sortable');
    sortableHeaders.forEach(header => {
        header.addEventListener('click', async () => {
            // Remover la clase "down" de todas las cabeceras
            sortableHeaders.forEach(otherHeader => {
                if (otherHeader !== header) {
                    otherHeader.querySelector('.arrow').classList.remove('down');
                }
            });

            // Agregar la clase "down" a la cabecera clicada
            header.querySelector('.arrow').classList.add('down');

            config.daysSearch = header.getAttribute('data-days');
            if (config.daysSearch) {
                const endpoint = `${config.artistsEndpoint}top-growth-by-playcounts/${config.daysSearch}`;
                await updateTable(endpoint, parseInt(config.daysSearch));
            } else {
                // Por defecto, cargar el listado completo de artistas
                await updateTable(config.artistsEndpoint, 90);
            }
        });
    });
}