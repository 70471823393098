import { config } from './config.js';
import {
    fetchArtistDetails
} from './artists.js';

import {
    toggleActiveClass,
    toggleDisplayHidden
} from './utils.js';

import {
    getMe
} from './session.js';


let operationType = 'SUBSCRIPTION';
let selectedArtistId = null;


const fetchArtists = async (searchTerm) => {
    const params = [`search=${encodeURIComponent(searchTerm)}`];
    let url = config.artistsEndpoint;

    if (params.length > 0) {
        url += '?' + params.join('&');
    }

    const response = await fetch(url);
    const artists = await response.json();
    return artists;
};


export const operateListeners = () => {
    const artistSearchInput = document.getElementById('operate-artist-search');
    const artistSearchInputLabel = document.getElementById('operate-artist-search-label');
    const artistList = document.getElementById('operate-artist-list');
    const artistListLabel = document.getElementById('operate-artist-list-label');
    const loadingSpinner = document.getElementById('operate-loading-spinner');
    const buyButton = document.getElementById('buy-button');
    const sellButton = document.getElementById('sell-button');
    const artistInfo = document.getElementById('operate-artist-info');
    const quantityInput = document.getElementById('quantity');
    const operateForm = document.getElementById('operate-form');
    const maxShellContainer = document.getElementById('max-shell-container');
    const maxShell = document.getElementById('max-shell');
    let timeoutId;

    artistSearchInput.addEventListener('input', () => {
        clearTimeout(timeoutId);
        const searchTerm = artistSearchInput.value;

        toggleDisplayHidden(artistList, false);
        toggleDisplayHidden(artistListLabel, false);
        toggleDisplayHidden(loadingSpinner, false);

        if (searchTerm) {
            toggleDisplayHidden(loadingSpinner, true);
            timeoutId = setTimeout(async () => {
                const results = await fetchArtists(searchTerm);
                artistList.innerHTML = '';
                toggleDisplayHidden(loadingSpinner, false);

                if (results.length > 0) {
                    toggleDisplayHidden(artistList, true);
                    toggleDisplayHidden(artistListLabel, true);
                    results.forEach(artist => {
                        const div = document.createElement('div');
                        div.textContent = artist.name;
                        div.classList.add('artist-item');
                        div.addEventListener('click', async () => {
                            artistSearchInput.value = artist.name;
                            artistList.innerHTML = '';
                            toggleDisplayHidden(artistList, false);
                            toggleDisplayHidden(artistListLabel, false);
                            const response = await fetch(`/views/artist-small.html`);
                            const html = await response.text();
                            artistInfo.innerHTML = html;
                            
                            selectedArtistId = artist.id;
                            await fetchArtistDetails(artist.id);
                            const me = localStorage.getItem('me');
                            const meObject = JSON.parse(me);
                            console.log(me)
                            console.log(meObject)
                            quantityInput.max = Number(meObject.credits).toFixed(2);
                        });
                        artistList.appendChild(div);
                    });
                } else {
                    toggleDisplayHidden(artistList, false);
                    toggleDisplayHidden(artistListLabel, false);
                }
            }, 2000);
        }
    });

    // Botones para comprar y vender
    buyButton.addEventListener('click', () => {
        operationType = 'SUBSCRIPTION';
        toggleActiveClass(buyButton, sellButton);
        artistSearchInput.value = '';
        artistInfo.innerHTML = '';
        toggleDisplayHidden(artistSearchInput, true);
        toggleDisplayHidden(artistSearchInputLabel, true);
        toggleDisplayHidden(maxShellContainer, false);
        artistList.innerHTML = '';
        toggleDisplayHidden(artistList, false);
        toggleDisplayHidden(artistListLabel, false);
        
        const me = localStorage.getItem('me');
        const meObject = JSON.parse(me);
        quantityInput.max = Number(meObject.credits).toFixed(2);
    });

    sellButton.addEventListener('click', async () => {
        operationType = 'REFUND';
        toggleActiveClass(sellButton, buyButton);
        toggleDisplayHidden(artistSearchInput, false);
        toggleDisplayHidden(artistList, true);
        toggleDisplayHidden(artistListLabel, true);
        toggleDisplayHidden(artistSearchInputLabel, false);
        const token = localStorage.getItem('token');
        let me = localStorage.getItem('me');
        if (token && !me) {
            await getMe(token);
            me = localStorage.getItem('me');
        }

        const artists = JSON.parse(me).stocks;
        
        artists.forEach(artist => {
            const div = document.createElement('div');
            div.textContent = artist.name;
            div.classList.add('artist-item');
            div.addEventListener('click', async () => {
                const artistItems = document.querySelectorAll('.artist-item');
                artistItems.forEach(item => {
                    item.classList.remove('active');
                });
                div.classList.add('active');
                artistSearchInput.value = artist.name;
                const response = await fetch(`/views/artist-small.html`);
                const html = await response.text();
                artistInfo.innerHTML = html;
                
                selectedArtistId = artist.artist_id;
                await fetchArtistDetails(artist.artist_id);
                toggleDisplayHidden(maxShellContainer, true);
                maxShell.innerText = artist.artist_amount.toFixed(2);
                quantityInput.max = artist.artist_amount.toFixed(2);
            });
            artistList.appendChild(div);
        });

    });

    operateForm.addEventListener('submit', async (event) => {
        event.preventDefault();
        const quantity = Number(quantityInput.value);

        if (!selectedArtistId) {
            alert('Por favor, selecciona un artista antes de enviar la operación.');
            return;
        }

        if (quantity <= 0) {
            alert('Por favor, ingresa una cantidad válida.');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${config.baseUrl}/operations`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    artist_id: selectedArtistId,
                    amount: quantity,
                    operation_type: operationType
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error realizando la operación');
            }

            const result = await response.json();
            alert('Operación realizada con éxito');
        } catch (error) {
            console.error('Error enviando la operación:', error);
            alert('Error realizando la operación: ' + error.message);
        }
    });
}
