import {config} from './config.js';

import {
    navigateToPage
} from './router.js';

export const registerListeners = () => {
    document.getElementById('registerForm').addEventListener('submit', async (event) => {
        event.preventDefault();
        const username = document.getElementById('username').value;
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;

        const response = await fetch(`${config.baseUrl}/users`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, email, password }),
        });

        if (response.ok) {
            navigateToPage('login', 'ok');
        }
    });
};