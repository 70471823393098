const baseUrl = 'https://musicstonks.com/api';
// const baseUrl = 'http://localhost:3000';
export const config = {
    baseUrl: baseUrl,
    byListeners: false,
    artistsEndpoint: `${baseUrl}/artists/`,
    tagsEndpoint: `${baseUrl}/tags`,
    currentTag: '',
    currentSearch: '',
    daysSearch: false,
    allTags: [], // Almacenar todas las tags// Selector del loader
};
