import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import {config} from './config.js';

import {
    formatNumber
} from './utils.js';


// Función para obtener el ID del artista desde la URL
export const getArtistIdFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('id');
};

// Función para renderizar la información del artista
export const renderArtistInfo = (artist) => {
    const artistNameElement = document.getElementById('artist-name');
    const artistValueElement = document.getElementById('artist-value');
    const artistTagsElement = document.getElementById('artist-tags');
    const artistBioElement = document.getElementById('artist-bio');
    const artistImgElement = document.getElementById('artist-img');
    const growth7DaysElement = document.getElementById('growth-7-days');
    const growth30DaysElement = document.getElementById('growth-30-days');
    const growth90DaysElement = document.getElementById('growth-90-days');

    if (artistNameElement) {
        artistNameElement.textContent = artist.name;
    }
    if (artistValueElement) {
        if (config.byListeners) {
            artistValueElement.textContent = formatNumber(artist.stocks_by_listeners);
        } else {
            artistValueElement.textContent = formatNumber(artist.stocks_by_playcounts_moving_average);
        }
    }
    if (artistTagsElement) {
        artistTagsElement.innerHTML = 'Tags: ';
        let tags = artist.tags.join(', ');
        artistTagsElement.innerHTML += tags;
    }
    if (artistBioElement && artist.bio) {
        artistBioElement.innerHTML = artist.bio.replace(/\n/g, "<br>");
    }
    if (artistImgElement) {
        artistImgElement.querySelector('img').src = artist.image_url;
    }
    if (growth7DaysElement) {
        const growth7Days = artist.growth_7d;
        growth7DaysElement.textContent = growth7Days !== '0' ? `${formatNumber(growth7Days)}%` : 'N/A';
        growth7DaysElement.style.color = growth7Days >= 0 ? 'green' : 'red';
    }
    if (growth30DaysElement) {
        const growth30Days =  artist.growth_30d;
        growth30DaysElement.textContent = growth30Days !== '0' ? `${formatNumber(growth30Days)}%` : 'N/A';
        growth30DaysElement.style.color = growth30Days >= 0 ? 'green' : 'red';
    }
    if (growth90DaysElement) {
        const growth90Days =  artist.growth_90d;
        growth90DaysElement.textContent = growth90Days !== '0' ? `${formatNumber(growth90Days)}%` : 'N/A';
        growth90DaysElement.style.color = growth90Days >= 0 ? 'green' : 'red';
    }

    const imgs = document.querySelectorAll('.lazy');

    imgs.forEach(img => {
        // Al cargar la imagen, añade la clase 'loaded'
        img.onload = () => {
            img.classList.add('loaded');
        };
    });

};

// Función para renderizar la gráfica del artista
export const renderArtistChart = (artist) => {
    const canvas = document.getElementById('artist-chart');
    if (!canvas) {
        console.error('Canvas element not found');
        return;
    }

    // Obtener datos para la gráfica
    const days = 90; // Cambiar según el período deseado
    const statsForChart = artist.stats.slice(0, days).reverse();
    const dates = statsForChart.map(stat => new Date(stat.date));
    const dailyPlaycounts = statsForChart.map(stat => stat.daily_playcounts_moving_average / 1000);

    let growthLastNDays = artist.growth_90d;
    if (days === 7) {
        growthLastNDays = artist.growth_7d;
    } else if (days === 30) {
        growthLastNDays = artist.growth_30d;
    }

    const ctx = canvas.getContext('2d');
    // Dentro de la configuración de options en renderArtistChart
    // Calcula los valores mínimos y máximos de las fechas en el eje X
    const minDate = new Date(Math.min(...dates.map(date => new Date(date))));
    const maxDate = new Date(Math.max(...dates.map(date => new Date(date))));

    // Calcula el padding (por ejemplo, un 5% del rango de fechas)
    const dateRange = maxDate - minDate;
    const paddingMilliseconds = dateRange * 0.01;

    // Ajusta las fechas mínimas y máximas con el padding
    const adjustedMinDate = new Date(minDate.getTime() - paddingMilliseconds);
    const adjustedMaxDate = new Date(maxDate.getTime() + paddingMilliseconds);

    const totalDuration = 1500;
    const delayBetweenPoints = totalDuration / dailyPlaycounts.length;
    const previousY = (ctx) => ctx.index === 0 ? ctx.chart.scales.y.getPixelForValue(100) : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1].getProps(['y'], true).y;
    const animation = {
        x: {
            type: 'number',
            easing: 'linear',
            duration: delayBetweenPoints,
            from: NaN, // the point is initially skipped
            delay(ctx) {
                if (ctx.type !== 'data' || ctx.xStarted) {
                    return 0;
                }
                ctx.xStarted = true;
                return ctx.index * delayBetweenPoints;
            }
        },
        y: {
            type: 'number',
            easing: 'linear',
            duration: delayBetweenPoints,
            from: previousY,
            delay(ctx) {
                if (ctx.type !== 'data' || ctx.yStarted) {
                    return 0;
                }
                ctx.yStarted = true;
                return ctx.index * delayBetweenPoints;
            }
        }
    };

    const chart = new Chart(ctx, {
        type: 'line',
        data: {
            labels: dates,
            datasets: [{
                data: dailyPlaycounts,
                borderColor: growthLastNDays >= 0 ? 'green' : 'red',
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderWidth: 3,
                pointRadius: 0, // Oculta los puntos en la línea,
                pointHoverRadius: 7,  // Tamaño de la bolita al pasar el cursor
                pointHitRadius: 10,  // Aumenta el área de activación para el tooltip
            }]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            let label = context.dataset.label || '';
                            if (label) {
                                label += ': ';
                            }
                            label += formatNumber(context.raw);  // Cambia a 2 decimales
                            return label;
                        }
                    }
                }
            },
            elements: {
                line: {
                    tension: 0.1 // Ajusta la suavidad de la línea
                },
                point: {
                    radius: 10,  // Ocultar los puntos de los datos
                    hitRadius: 100
                }
            },
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: 'day',
                        tooltipFormat: 'dd/MM/yyyy'
                    },
                    ticks: {
                        maxRotation: 0,
                        autoSkipPadding: 20
                    },
                    grid: {
                        display: false,  // Ocultar la cuadrícula del eje x
                        drawBorder: false  // No dibujar la línea de borde del eje x
                    },
                    offset: true,
                    min: adjustedMinDate,
                    max: adjustedMaxDate 
                },
                y: {
                    beginAtZero: false
                },
            },
            // Configuración de animación
            animation
        }
    });

};

export const fetchArtistDetails = async (artistId) => {
    try {
        if (!artistId) {
            throw new Error('Artist ID not provided');
        }
        
        const artistEndpoint = `${config.baseUrl}/artists/${artistId}`; // Endpoint del artista específico
        const response = await fetch(artistEndpoint);
        if (!response.ok) {
            throw new Error('Failed to fetch artist details');
        }
        const artist = await response.json();
        renderArtistInfo(artist);
        renderArtistChart(artist);
    } catch (error) {
        console.error('Error fetching artist details:', error);
        return null;
    }
};