import {
    navigateToPage
} from './router.js';

export let userLang = navigator.language || navigator.languages[0];

export const formatNumber = number => {
    return new Intl.NumberFormat(userLang, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(number);
};

export const showLoader = () => {
    const loader = document.getElementById('loader');
    loader.style.display = 'flex'; // Mostrar el loader
};

export const hideLoader = () => {
    const loader = document.getElementById('loader');
    loader.style.display = 'none'; // Ocultar el loader
};


export const searchArtistListeners = () => {

    const handleSearchButtonInteraction = () => {
        var searchValue = document.getElementById('search-artist').value;
        if (searchValue) {
            navigateToPage('search', encodeURI(searchValue));
        }
    };

    const searchButton = document.getElementById('search-button');
    searchButton.addEventListener('mousedown', handleSearchButtonInteraction);
    searchButton.addEventListener('touchstart', (event) => {
        event.preventDefault();
        handleSearchButtonInteraction();
    });


    const searchInput = document.getElementById('search-artist');

    searchInput.addEventListener('focus', () => {
        var searchButton = document.getElementById('search-button');
        var authButtons = document.getElementById('auth-buttons');

        searchButton.classList.add('active');
        authButtons.classList.add('inactive');
    });
    searchInput.addEventListener('blur', () => {
        var searchButton = document.getElementById('search-button');
        var authButtons = document.getElementById('auth-buttons');

        searchButton.classList.remove('active');
        authButtons.classList.remove('inactive');
    });
    // Agregar un event listener para el evento 'keydown' en el input
    searchInput.addEventListener('keydown', (event) => {
        // Verificar si la tecla presionada es 'Enter'
        if (event.key === 'Enter') {
            // Evitar el comportamiento por defecto de la tecla 'Enter' en un formulario
            event.preventDefault();
            // Obtener el valor de búsqueda
            var searchValue = searchInput.value;
            // Navegar a la página con el valor codificado URI
            navigateToPage('search', encodeURI(searchValue));
        }
    });
};


// Función para alternar la clase 'active' en los botones
export const toggleActiveClass = (activeButton, inactiveButton) => {
    activeButton.classList.add('active');
    inactiveButton.classList.remove('active');
};

// Función para mostrar u ocultar un elemento
export const toggleDisplayHidden = (element, show) => {
    if (show) {
        element.classList.remove('hidden'); // Asegúrate de tener una clase 'hidden' en CSS
    } else {
        element.classList.add('hidden');
    }
};