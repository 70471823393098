import Navigo from 'navigo';
import {config} from './config.js';
import {
    fetchArtistDetails
} from './artists.js';

import {
    getSearchArtists
} from './search.js';

import {
    registerListeners
} from './register.js';

import {
    tokenEvents,
    logoutListeners
} from './session.js';

import {
    loginListeners
} from './login.js';


import {
    searchArtistListeners 
} from './utils.js';

import {
    loadTags,
    addSortableClick,
    updateTable
} from './artists-table.js';

import {
    operateListeners
} from './operate.js';

const loadPage = async (page, hasArtistTable, keepSearch) => {
    
    config.currentTag = '';
    if (!keepSearch) {
        config.currentSearch = '';
    }

    const container = document.querySelector('.container');
    container.innerHTML = '';
    try {
        const response = await fetch(`/views/${page}.html`);
        if (!response.ok) {
            throw new Error('Error al cargar la página');
        }
        const html = await response.text();
        container.innerHTML = html;
        await tokenEvents();
        if (hasArtistTable) {
            await loadTags();
            await addSortableClick();
            await updateTable();
        }
        searchArtistListeners();

    } catch (error) {
        console.error(error);
    }
}

const router = new Navigo('/');



export const init = () => {

    const logo = document.getElementById('logo');
    logo.addEventListener('click', () => {
        navigateToPage('home');
    });


    router
        .on('/', async () => {
            await loadPage('home', true);
        })
        .on('/home', async () => {
            await loadPage('home', true);
        })
        .on('/artist/:id', async (params) => {
            await loadPage(`artist`);
            await fetchArtistDetails(params.data.id);
        })
        .on('/search/:search', async (params) => {
            config.currentSearch = params.data.search;
            await loadPage('search', true, true);
            getSearchArtists(params.data.search);
        })
        .on('/register', async () => {
            await loadPage(`register`);
            await registerListeners();
        })
        .on('/login', async () => {
            await loadPage(`login`);
            loginListeners();
        })
        .on('/operate', async () => {
            await loadPage(`operate`);
            operateListeners();
        })
        .resolve(); // Resuelve la ruta actual

}

export const navigateToPage = async (page, query) => {
    switch (page) {
        case 'artist':
            router.navigate(`/artist/${query}`);
            break;
        case 'search':
            router.navigate(`/search/${query}`);
            break;
        case 'register':
            router.navigate(`/register`);
            break;
        case 'login':
            router.navigate(`/login`);
            break;
        case 'home':
        default:
            router.navigate(`/`);
            break;
    }
};

