import {config} from './config.js';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';

export const showLoginUiElements = (userInfo) => {
    const profileButton = document.getElementById('profile-button');
    if (profileButton) {
        profileButton.innerHTML = userInfo.username.slice(0,2);
        profileButton.classList.add('shown');
    }
    
    const authButtons = document.getElementById('auth-buttons');
    if (authButtons) {
        authButtons.classList.add('hidden');
    }
    
    const topUser = document.getElementById('top-user');
    if (topUser) {
        topUser.classList.add('shown');
    }
    
    const actualCredit = document.getElementById('actual-credit');
    if (actualCredit) {
        actualCredit.innerHTML = userInfo.credits;
    }

    var userInvestmentList = document.getElementById('user-investment');
    if (userInvestmentList && userInfo.stocks && userInfo.stocks.length) {
        userInvestmentList.classList.remove('hidden');
        userInfo.stocks.forEach(stock => {

            const lastDateKey = Object.keys(userInfo.evolution).slice(-1)[0];
            const artistLastEvolution = userInfo.evolution[lastDateKey].artists.find(artist => artist.artist_id === stock.artist_id);

            const listItem = document.createElement('li');
            listItem.innerHTML = `<b>${stock.name}:</b> <span> ${stock.artist_amount.toFixed(2)} Creditos - ${stock.stocks.toFixed(2)} Participaciones</span> - Total invertido(incluyendo entradas y salidas): ${artistLastEvolution.investment} creditos - Rentabilidad: ${artistLastEvolution.profitability.toFixed(2)} %`;
            userInvestmentList.appendChild(listItem);
        });
        
        const totalListItem = document.createElement('li');
        totalListItem.classList.add('total');
        totalListItem.innerHTML = `<b>Total:</b> <span> ${(userInfo.totalAmount + Number(userInfo.credits)).toFixed(2)} Creditos (${(userInfo.totalAmount).toFixed(2)} Invertidos, ${Number(userInfo.credits).toFixed(2)} en cartera)</span>`;
        userInvestmentList.appendChild(totalListItem);

        const investmentProfitabilitylistItem = document.createElement('li');
        investmentProfitabilitylistItem.classList.add('investment-profitability');
        investmentProfitabilitylistItem.innerHTML = `<b>Rentabilidad de la inverisón:</b> <span> ${userInfo.investmentProfitability.toFixed(2)} % </span>`;
        userInvestmentList.appendChild(investmentProfitabilitylistItem);

        const totalProfitabilityListItem = document.createElement('li');
        totalProfitabilityListItem.classList.add('total-profitability');
        totalProfitabilityListItem.innerHTML = `<b>Rentabilidad total:</b> <span> ${userInfo.totalProfitability.toFixed(2)} % </span>`;
        userInvestmentList.appendChild(totalProfitabilityListItem);
    }
}

const getPaleColor  = (index) => {
    const grayPalette = [
        "#ff9999", "#ffb380", "#ffd480", "#b3e6b3", "#80d4ff", 
        "#b3b3ff", "#ff99cc", "#99e6e6", "#d9b3ff", "#99c2ff",
        "#ffcc99", "#ffb3b3", "#ffeb99", "#b3ffcc", "#99d6ff",
        "#d1b3ff", "#ff99b3", "#b3ffe6", "#e6ccff", "#80b3ff",
        "#ffad99", "#ffcc80", "#ffdb99", "#b3ffb3", "#99ccff",
        "#b380ff", "#ff80b3", "#99ffe6", "#e6b3ff", "#99b3ff"
    ];

    return grayPalette[index % grayPalette.length];
};

// Función para generar colores aleatorios
const getRandomColor = () => {
    // const letters = '0123456789ABCDEF';
    // let color = '#';
    // for (let i = 0; i < 6; i++) {
    //     color += letters[Math.floor(Math.random() * 16)];
    // }
    // return color;
    const value = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
    return `#${value}${value}${value}`;
};

export const showAmountEvolution = (data) => {

    const dates = Object.keys(data);

    const artistNames = data[Object.keys(data)[0]].artists.map(artist => artist.artist_name);
    const datasets = artistNames.map((artistName, i) => {
        return {
            label: artistName,
            data: dates.map(date => {
                if (data[date] && data[date].artists) {
                    const artistData = data[date].artists.find(artist => artist.artist_name === artistName);
                    return artistData ? parseFloat(artistData.amount) : 0;
                } else {
                    return 0;
                }
            }),
            borderColor: getPaleColor(i),
            backgroundColor: getPaleColor(i),
            borderWidth: 3,
            pointRadius: 0,
            pointHoverRadius: 7,
            pointHitRadius: 10
        };
    });

    
    const lastDateKey = dates.slice(-1)[0];
    const isPositive = data[lastDateKey].total_amount > 1000;

    datasets.push({
        label: 'Total',
        data: dates.map(date => {
            if (data[date] && data[date].total_amount !== undefined) {
                return data[date].total_amount;
            } else {
                return 0;
            }
        }),
        borderColor: isPositive >= 0 ? 'green' : 'red',
        backgroundColor: isPositive >= 0 ? 'green' : 'red',
        borderWidth: 3,
        pointRadius: 0,
        pointHoverRadius: 7,
        pointHitRadius: 10
    });
    
    generateChart('amount-evolution', dates, datasets);
}
export const showProfitEvolution = (data) => {

    const dates = Object.keys(data);

    const artistNames = data[Object.keys(data)[0]].artists.map(artist => artist.artist_name);
    const datasets = artistNames.map((artistName, i) => {
        return {
            label: artistName,
            data: dates.map(date => {
                if (data[date] && data[date].artists) {
                    const artistData = data[date].artists.find(artist => artist.artist_name === artistName);
                    return artistData ? parseFloat(artistData.profitability) : 0;
                } else {
                    return 0;
                }
            }),
            borderColor: getPaleColor(i),
            backgroundColor: getPaleColor(i),
            borderWidth: 3,
            pointRadius: 0,
            pointHoverRadius: 7,
            pointHitRadius: 10
        };
    });

    
    const lastDateKey = dates.slice(-1)[0];
    const isPositive = data[lastDateKey].total_amount > 1000;

    datasets.push({
        label: 'Total',
        data: dates.map(date => {
            if (data[date] && data[date].total_amount !== undefined) {
                return data[date].total_profitability;
            } else {
                return 0;
            }
        }),
        borderColor: isPositive >= 0 ? 'green' : 'red',
        backgroundColor: isPositive >= 0 ? 'green' : 'red',
        borderWidth: 3,
        pointRadius: 0,
        pointHoverRadius: 7,
        pointHitRadius: 10
    });
    generateChart('profitability-evolution', dates, datasets, true);
}

const generateChart = (id, dates, datasets, isPercent) => {

    // Dentro de la configuración de options en renderArtistChart
    // Calcula los valores mínimos y máximos de las fechas en el eje X
    const minDate = new Date(Math.min(...dates.map(date => new Date(date))));
    const maxDate = new Date(Math.max(...dates.map(date => new Date(date))));

    // Calcula el padding (por ejemplo, un 5% del rango de fechas)
    const dateRange = maxDate - minDate;
    const paddingMilliseconds = dateRange * 0.01;

    // Ajusta las fechas mínimas y máximas con el padding
    const adjustedMinDate = new Date(minDate.getTime() - paddingMilliseconds);
    const adjustedMaxDate = new Date(maxDate.getTime() + paddingMilliseconds);

    // let totaldata = [];
    // datasets.forEach(element => {
    //     totaldata.push(...element.data)
    // });

    // totaldata = totaldata.filter(data => !isNaN(data));
    
    // const minY = Math.min(...totaldata).toFixed(2);
    // const maxY = Math.max(...totaldata).toFixed(2);

    // // Calcula un margen basado en un porcentaje del rango
    // const paddingPercentage = 0.1; // Por ejemplo, 10% de padding
    // const yPadding = (maxY - minY) * paddingPercentage;
    
    // // Ajusta los valores min y max con el padding
    // const adjustedMinY = minY - yPadding;
    // const adjustedMaxY = maxY + yPadding;
    
    const canvas = document.getElementById(id);
    if (!canvas) {
        console.error('Canvas element not found');
        return;
    }
    const ctx = canvas.getContext('2d');
    const chart = new Chart(ctx, {
        type: 'line',
        data: {
            labels: dates,
            datasets: datasets
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: true
                },
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            let label = context.dataset.label || '';
                            if (label) {
                                label += ': ';
                            }
                            label += parseFloat(context.raw).toFixed(2);
                            if (isPercent) {
                                return label + '%';
                            } else {
                                return label;
                            }
                        }
                    }
                }
            },
            elements: {
                line: {
                    tension: 0.1 // Ajusta la suavidad de la línea
                },
                point: {
                    radius: 10,  // Ocultar los puntos de los datos
                    hitRadius: 100
                }
            },
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: 'day',
                        tooltipFormat: 'dd/MM/yyyy'
                    },
                    ticks: {
                        maxRotation: 0,
                        autoSkipPadding: 20
                    },
                    grid: {
                        display: false,  // Ocultar la cuadrícula del eje x
                        drawBorder: false  // No dibujar la línea de borde del eje x
                    },
                    offset: true,
                    min: adjustedMinDate,
                    max: adjustedMaxDate 
                },
                y: {
                    beginAtZero: false,
                    ticks: {
                        callback: function(value) {
                            if (isPercent) {
                                return value + '%';
                            } else {
                                return value;
                            }
                        }
                    },
                    offset: true,
                    // min: adjustedMinY,  // Establece el valor mínimo del eje Y
                    // max: adjustedMaxY   // Establece el valor máximo del eje Y
                }
            }
        }
    });
}
